.invoice-card-header {
  display: flex;
  justify-content: space-between;
}

.selectedMsg {
  background-color: rgba(12, 77, 162, 0.1);
}

a {
  text-decoration: none;
}
.unreadConversation {
  background-color: rgba(12, 77, 162, 0.1) !important;
}

.css-jozmg0-MuiPaper-root-MuiAccordion-root {
  background: rgb(17, 25, 42) !important;
}
.accordion-submodule .accordion {
  background: rgb(17, 25, 42) !important;
}
.accordion-submodule .accordion-module-name {
  font-size: 0.875rem !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.accordion-submodule .expand-icon {
  color: rgba(255, 255, 255, 0.7) !important;
}

.accordion-submodule .submodule-name {
  font-size: 14px !important;
  font-weight: 400 !important;
}

/* rgba(255, 255, 255, 0.7) */

.active-conv {
  background-color: rgba(38, 89, 151, 0.288) !important;
  color: white !important;
}
