.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  text-align: center;
}
.login-page {
  height: 100vh;
}

.login-page .login-wrapper {
  padding-top: 120px;
  display: flex;
  justify-content: center;
}

/* register page */
.register-page {
  height: 100vh;
}
.register-page .register-left-col {
  height: 100vh;
}

.register-page .register-right-col {
  height: 100%;
  padding-top: 120px;
}

.register-page .register-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.register-page .form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
}

.css-1wmosz1-MuiTableCell-root {
  text-align: center !important;
}
